import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Grid, Container, Paper } from '@material-ui/core';

import { PageLayout } from '../layouts';
import { Box, HeroTitle } from '../components';

function Home({ data }) {
  const { allMdx, headImage, images } = data;
  return (
    <PageLayout title="Home" image={headImage ? headImage.childImageSharp.resize : null} pathnames={['/', ...allMdx.nodes.map(({ slug }) => `/${slug}`)]}>
      <HeroTitle headImage={headImage} />
      {allMdx.nodes.map((child, i) => {
        const isOdd = i & 1;
        const colorClass = !isOdd ? 'contrastText' : 'primary';
        const textColor = !isOdd ? 'primary' : 'contrastText';
        const image = images.nodes.find((f) => f.childImageSharp.fluid.originalName === child.frontmatter.contentImages[0]);
        return (
          <Box key={child.id} bgColorClass={colorClass} bgPattern={isOdd ? child.frontmatter.bgPattern : null}>
            <Container maxWidth="lg" style={{ paddingTop: '15vh', paddingBottom: '15vh' }}>
              {image ? (
                isOdd ? (
                  <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item lg={4} xs={12}>
                      <MDXRenderer title={child.title} linkProps={{ path: child.path }} typographyProps={{ colorClass: textColor }}>
                        {child.body}
                      </MDXRenderer>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Paper elevation={4}>
                        <Img fluid={image.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
                      </Paper>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item lg={6} xs={12}>
                      <Paper elevation={4}>
                        <Img fluid={image.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
                      </Paper>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <MDXRenderer title={child.title} linkProps={{ path: child.path }} typographyProps={{ colorClass: textColor }}>
                        {child.body}
                      </MDXRenderer>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                  <Grid item xs={12}>
                    <MDXRenderer typographyProps={{ colorClass: textColor, align: 'center' }}>{child.body}</MDXRenderer>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Box>
        );
      })}
    </PageLayout>
  );
}

export const query = graphql`
  query HomePageQuery {
    headImage: file(relativePath: { eq: "bretter.jpg" }) {
      id
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
        fixed(width: 4272, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    images: allFile(
      filter: {
        relativePath: {
          in: [
            "Sonderpalette 2100x1000mm, aus Recyclingholz.jpg"
            "wald.jpg"
            "mensch-wirtschaft-umwelt.jpg"
            "Zerlegen Hand.jpg"
            "office-ueber-uns.jpg"
            "produktion.jpg"
            "Recyclingholz, Kanthölzer und Bretter.jpg"
            "Unternehmenskultur.jpg"
            "philosophie.jpg"
          ]
        }
      }
    ) {
      nodes {
        id
        childImageSharp {
          fluid(quality: 100) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allMdx(filter: { slug: { regex: "/ueber-uns*|philosophie*/" } }, sort: { order: ASC, fields: frontmatter___homePageOrder }) {
      nodes {
        slug
        id
        body
        frontmatter {
          homePageOrder
          template
          bgPattern
          loadChildPages
          order
          title
          keywords
          contentImages
          excerpt
        }
      }
    }
  }
`;

Home.propTypes = { data: PropTypes.object.isRequired };

Home.defaultProps = {};

export default Home;

// <Box>
//   <SEO image={headImage.childImageSharp.resize} pathnames={[]} logo={logo.childImageSharp.resize} />
//   <HeroAppbar bgColor="primary" handleMenuButtonClick={() => dispatch(toggleDrawer(drawerIsOpen))}>
//     <AppbarLinks />
//   </HeroAppbar>
//   <Box style={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
//     <Img fixed={headImage.childImageSharp.fixed} objectFit="cover" objectPosition="50% 50%" alt="head image" />
//     <div className={`${classes.imageBackdrop} ${classes.primaryDark}`} />
//     <div className={classes.heroContainerContent}>
//       <Grid container direction="row" justify="flex-start" alignItems="center" style={{ height: '100%' }}>
//         <Grid item xs={12}>
//           <Container maxWidth="lg">
//             <Typography variant="overline" style={{ fontSize: '1.1rem' }}>
//               Professionell, freundlich und kooperativ.
//             </Typography>
//             <Typography variant="h4" gutterBottom>
//               STARK Dynamics GmbH
//             </Typography>
//             <Typography variant="subtitle1" style={{ fontSize: '1.2rem' }}>
//               Um die Anforderungen unserer Kunden zu erfüllen,
//             </Typography>
//             <Typography variant="subtitle1" style={{ fontSize: '1.2rem' }} gutterBottom>
//               steht persönliche Kommunikation im Mittelpunkt unserer Unternehmenspolitik.
//             </Typography>
//             <ButtonLink linkProps={{ to: '/ueber-uns' }} buttonProps={{ variant: 'contained', color: 'secondary' }}>
//               Mehr erfahren
//             </ButtonLink>
//           </Container>
//         </Grid>
//       </Grid>
//     </div>
//   </Box>
//   <Box bgColorClass="contrastText" marginClass="marginBoth">
//     <Grid container direction="row" justify="center" alignItems="center">
//       <Grid item lg={12}>
//         <Typography align="center" variant="h5" gutterBottom paragraph>
//           Wir sind die STARKE Schulter, auf die Sie sich verlassen können.
//         </Typography>
//       </Grid>
//     </Grid>
//     <Grid container direction="row" justify="space-evenly" alignItems="stretch" style={{ marginTop: 50 }}>
//       <Grid item lg={3}>
//         <List>
//           <ListItem>
//             <ListItemText id="listitem1" primary="Krisensicher" />
//             <ListItemSecondaryAction>
//               <Checkbox edge="end" checked inputProps={{ 'aria-labelledby': 'listitem1' }} />
//             </ListItemSecondaryAction>
//           </ListItem>
//           <ListItem>
//             <ListItemText id="listitem2" primary="6000 m²" />
//             <ListItemSecondaryAction>
//               <Checkbox edge="end" checked inputProps={{ 'aria-labelledby': 'listitem2' }} />
//             </ListItemSecondaryAction>
//           </ListItem>
//           <ListItem>
//             <ListItemText id="listitem3" primary="Standard Produkte" />
//             <ListItemSecondaryAction>
//               <Checkbox edge="end" checked inputProps={{ 'aria-labelledby': 'listitem3' }} />
//             </ListItemSecondaryAction>
//           </ListItem>
//           <ListItem>
//             <ListItemText id="listitem4" primary="Sonderproduktion" />
//             <ListItemSecondaryAction>
//               <Checkbox edge="end" checked inputProps={{ 'aria-labelledby': 'listitem4' }} />
//             </ListItemSecondaryAction>
//           </ListItem>
//           <ListItem>
//             <ListItemText id="listitem5" primary="Palettenrecycling, Ressourcenschutz" />
//             <ListItemSecondaryAction>
//               <Checkbox edge="end" checked inputProps={{ 'aria-labelledby': 'listitem5' }} />
//             </ListItemSecondaryAction>
//           </ListItem>
//         </List>
//       </Grid>
//       <Grid item lg={4}>
//         <Typography variant="body1" align="justify" gutterBottom paragraph>
//           Um die Anforderungen unserer Kunden zu erfüllen, steht persönliche Kommunikation im Mittelpunkt unserer Unternehmenspolitik. Einfache Strukturen und eindeutige
//           Verantwortlichkeiten bieten dem Kunden Sicherheit, jederzeit den richtigen Ansprechpartner zu finden. Dabei spielt es keine Rolle ob es um eine oder 100.000
//           Paletten geht.
//         </Typography>
//         <Typography variant="body1" align="justify" gutterBottom paragraph>
//           Bei der Herstellung von Paletten, Kisten oder Sonderprodukten setzen wir sowohl Neu- als auch Gebrauchtholz aus unserem Recyclingwerk ein. Optimierte
//           Arbeitsprozesse sowie geschultes Fachpersonal sorgen für optimale Nutzung der Ressourcen. Paletten, Transportböden, oder sonstige Sonderpaletten bis 6 m Länge
//           sind bei uns Standard. Ladungsträger, die für den Export gedacht sind, werden nach IPPC Standards, ISPM Nr. 15 behandelt.
//         </Typography>
//       </Grid>
//     </Grid>
//   </Box>
//   <Box marginClass="marginBottom" bgColorClass="primary" withRandomShapes>
//     <Container maxWidth="lg" style={{ paddingTop: '25vh', paddingBottom: '25vh' }}>
//       <Grid container direction="row" justify="space-evenly" alignItems="center">
//         <Grid item lg={4}>
//           <Typography colorClass="secondary" variant="h6" gutterBottom>
//             Umweltschutz / Recycling / Ressourcenschutz
//           </Typography>
//           <Typography colorClass="secondary" variant="subtitle1" gutterBottom align="justify" paragraph>
//             STARK Dynamics GmbH lebt seine Philosophie: „STARK für die Natur! Wir lieben die Natur und sind davon überzeugt, dass sowohl die Holzproduktion als auch das
//             Recycling von Holzprodukten dazu beitragen kann die Umwelt nachhaltig zu schützen.“
//           </Typography>
//           <Typography colorClass="secondary" variant="subtitle1" gutterBottom align="justify" paragraph>
//             Durch Industrialisierung und Globalisierung ist es immer einfacher und günstiger geworden Rohstoffe zu bekommen. So kommt es, dass gutes Material auf dem
//             Müllberg landet. Genau an diesem Punkt setzen wir an. Es ist uns besonders wichtig Holz welches als Abfall abgeführt wird, wiederaufzubereiten und in den Markt
//             zurückzuführen. Unsere „Null-Abfall-Politik“ hat gezeigt, dass auch unsere Kunden verstehen warum es wichtig ist, Holz wiederzuverwerten. Dieses Verständnis
//             hat uns geholfen einen großen Kundenstamm aufzubauen.
//           </Typography>
//         </Grid>
//         <Grid item lg={6}>
//           <Paper>
//             <Img fluid={recycling.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" alt="recycling image" />
//           </Paper>
//         </Grid>
//       </Grid>
//     </Container>
//   </Box>
// </Box>
